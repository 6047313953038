<template>
   <div class="collect_box">
     <ListContent />
   </div>
</template>



<script>

import ListContent from "@/components/listContent";
export default {
  name: "Product",
  components: {
    ListContent,
  },
  setup() {
    return {};
  },
};
</script>