import { createRouter, createWebHashHistory } from 'vue-router'
//import Home from '../views/Home.vue'
import Collect from '../views/collect.vue'

 
const routes = [
  {
    path: '/',
    redirect: '/collect'   
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: ()=>import('../views/login.vue')
  // },
  {
    path: '/collect',
    name: 'Collect',
    component:Collect
  },
  {
    path: '/collectDetail',
    name: 'CollectDetail',
    component: ()=>import('../views/collectDetail.vue')
  },
  {
    path: '/edit',
    name: 'eidt',
    component: ()=>import('../views/edit.vue')
  },
  {
    path: '/userPage',
    name: 'UserPage',
    component: ()=>import('../views/userPage.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: ()=>import('../views/test.vue')
  },
  {
    path: '/charts',
    name: 'charts',
    component: ()=>import('../views/charts.vue')
  },
  {
    path: '/storeRoom',
    name: 'storeRoom',
    component: ()=>import('../views/storeRoom.vue')
  },
  {
    path: '/exhibitRoom',
    name: 'exhibitRoom',
    component: ()=>import('../views/exhibitRoom.vue')
  },{
    path: '/inventory',
    name: 'inventory',
    component: ()=>import('../views/inventory.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
