<template>
  <!-- 左侧菜单 -->
  <div class="sidebar_box">
    <el-col :span="12" class="sidebar" :style="{'background':conf.color_leftbar}"   >
      <!-- show-checkbox -->
      <el-tree :data="museum" ref="treeRef" @check="handleCheckChange" show-checkbox
        :props="{ label: 'name', children: 'children' }" class="tree-area" @node-click="handleNodeClick">
        <template #default="{ node, data }">
          <span class="custom-tree-node">
            <span>{{ node.label }}</span>
            <span v-if="data.num && data.num > 0"> {{ data.num }}件/套 </span>
          </span>
        </template>
      </el-tree>

      <!-- <el-menu :default-active="defaultActive">
        <el-submenu index="1">
          <template #title>
            <span>保管单位</span>
          </template>
          <el-menu-item class="ej_bg" v-for="(item, index) in museum" :key="index"  :index="index" @click="setMuseumId(item.museum_id)">{{ item.museum_name }}</el-menu-item> 
         
        </el-submenu>

        <el-submenu index="2" v-if="relicNumber.year">
          <template #title>
            <span>年代</span>
          </template>
          <el-menu-item index="2-1" @click="setYear('')" class="allCollect ej_bg">
            <span>全部藏品</span> <span>{{ relicNumber.total }}件/套</span>
          </el-menu-item>
          <el-submenu index="2-2" class="ej_bg">
            <template #title>考古学年代</template>
            <el-menu-item index="1-2-1" @click="setYear('旧石器时代')"> <span>旧石器时代</span> <span v-show="relicNumber.year['旧石器时代'] != 0">{{ relicNumber.year['旧石器时代'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-2-2" @click="setYear('新石器时代')"> <span>新石器时代</span> <span v-show="relicNumber.year['新石器时代'] != 0">{{ relicNumber.year['新石器时代'] }}件/套</span></el-menu-item>
          </el-submenu>
          <el-submenu index="2-3" class="ej_bg">
            <template #title>中国历史学年代</template>
            <el-menu-item index="1-3-1" @click="setYear('夏')">         <span>夏</span>         <span v-show="relicNumber.year['夏'] != 0">{{ relicNumber.year['夏'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-2" @click="setYear('商')">         <span>商</span>         <span v-show="relicNumber.year['商'] != 0">{{ relicNumber.year['商'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-3" @click="setYear('西周')">       <span>西周</span>       <span v-show="relicNumber.year['西周'] != 0">{{ relicNumber.year['西周'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-4" @click="setYear('东周')">       <span>东周</span>       <span v-show="relicNumber.year['东周'] != 0">{{ relicNumber.year['东周'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-5" @click="setYear('春秋')">       <span>春秋</span>       <span v-show="relicNumber.year['春秋时代'] != 0">{{ relicNumber.year['春秋时代'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-6" @click="setYear('战国')">       <span>战国</span>       <span v-show="relicNumber.year['战国时代'] != 0">{{ relicNumber.year['战国时代'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-7" @click="setYear('秦')">         <span>秦</span>         <span v-show="relicNumber.year['秦'] != 0">{{ relicNumber.year['秦'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-8" @click="setYear('西汉')">       <span>西汉</span>       <span v-show="relicNumber.year['西汉'] != 0">{{ relicNumber.year['西汉'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-9" @click="setYear('东汉')">       <span>东汉</span>       <span v-show="relicNumber.year['东汉'] != 0">{{ relicNumber.year['东汉'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-10" @click="setYear('三国魏')">    <span>三国魏</span>     <span v-show="relicNumber.year['三国魏'] != 0">{{ relicNumber.year['三国魏'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-11" @click="setYear('三国蜀')">    <span>三国蜀</span>     <span v-show="relicNumber.year['三国蜀'] != 0">{{ relicNumber.year['三国蜀'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-12" @click="setYear('三国吴')">    <span>三国吴</span>     <span v-show="relicNumber.year['三国吴'] != 0">{{ relicNumber.year['三国吴'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-13" @click="setYear('西晋')">      <span>西晋</span>       <span v-show="relicNumber.year['西晋'] != 0">{{ relicNumber.year['西晋'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-14" @click="setYear('东晋')">      <span>东晋</span>       <span v-show="relicNumber.year['东晋'] != 0">{{ relicNumber.year['东晋'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-15" @click="setYear('东晋十六国')"><span>东晋十六国</span>  <span v-show="relicNumber.year['东晋十六国'] != 0">{{ relicNumber.year['东晋十六国'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-16" @click="setYear('南朝·宋')">   <span>南朝·宋</span>    <span v-show="relicNumber.year['南朝·宋'] != 0">{{ relicNumber.year['南朝·宋'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-17" @click="setYear('南朝·齐')">   <span>南朝·齐</span>    <span v-show="relicNumber.year['南朝·齐'] != 0">{{ relicNumber.year['南朝·齐'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-18" @click="setYear('南朝·梁')">   <span>南朝·梁</span>    <span v-show="relicNumber.year['南朝·梁'] != 0">{{ relicNumber.year['南朝·梁'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-19" @click="setYear('南朝·陈')">   <span>南朝·陈</span>    <span v-show="relicNumber.year['南朝·陈'] != 0">{{ relicNumber.year['南朝·陈'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-20" @click="setYear('北魏')">      <span>北魏</span>       <span v-show="relicNumber.year['北魏'] != 0">{{ relicNumber.year['北魏'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-21" @click="setYear('东魏')">      <span>东魏</span>       <span v-show="relicNumber.year['东魏'] != 0">{{ relicNumber.year['东魏'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-22" @click="setYear('北齐')">      <span>北齐</span>       <span v-show="relicNumber.year['北齐'] != 0">{{ relicNumber.year['北齐'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-23" @click="setYear('西魏')">      <span>西魏</span>       <span v-show="relicNumber.year['西魏'] != 0">{{ relicNumber.year['西魏'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-24" @click="setYear('北周')">      <span>北周</span>       <span v-show="relicNumber.year['北周'] != 0">{{ relicNumber.year['北周'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-25" @click="setYear('隋')">        <span>隋</span>         <span v-show="relicNumber.year['隋'] != 0">{{ relicNumber.year['隋'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-26" @click="setYear('唐')">        <span>唐</span>         <span v-show="relicNumber.year['唐'] != 0">{{ relicNumber.year['唐'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-27" @click="setYear('五代·后梁')"> <span>五代·后梁</span>   <span v-show="relicNumber.year['五代·后梁'] != 0">{{ relicNumber.year['五代·后梁'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-28" @click="setYear('五代·后唐')"> <span>五代·后唐</span>   <span v-show="relicNumber.year['五代·后唐'] != 0">{{ relicNumber.year['五代·后唐'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-29" @click="setYear('五代·后晋')"> <span>五代·后晋</span>   <span v-show="relicNumber.year['五代·后晋'] != 0">{{ relicNumber.year['五代·后晋'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-30" @click="setYear('五代·后汉')"> <span>五代·后汉</span>   <span v-show="relicNumber.year['五代·后汉'] != 0">{{ relicNumber.year['五代·后汉'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-31" @click="setYear('五代·后周')">  <span>五代·后周</span>  <span v-show="relicNumber.year['五代·后周'] != 0">{{ relicNumber.year['五代·后周'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-32" @click="setYear('十国')">      <span>十国</span>       <span v-show="relicNumber.year['十国'] != 0">{{ relicNumber.year['十国'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-33" @click="setYear('北宋')">      <span>北宋</span>       <span v-show="relicNumber.year['北宋'] != 0">{{ relicNumber.year['北宋'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-34" @click="setYear('南宋')">      <span>南宋</span>       <span v-show="relicNumber.year['南宋'] != 0">{{ relicNumber.year['南宋'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-35" @click="setYear('辽')">        <span>辽</span>         <span v-show="relicNumber.year['辽'] != 0">{{ relicNumber.year['辽'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-36" @click="setYear('西夏')">      <span>西夏</span>       <span v-show="relicNumber.year['西夏'] != 0">{{ relicNumber.year['西夏'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-37" @click="setYear('金')">        <span>金</span>         <span v-show="relicNumber.year['金'] != 0">{{ relicNumber.year['金'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-38" @click="setYear('元')">        <span>元</span>         <span v-show="relicNumber.year['元'] != 0">{{ relicNumber.year['元'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-39" @click="setYear('明')">        <span>明</span>         <span v-show="relicNumber.year['明'] != 0">{{ relicNumber.year['明'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-40" @click="setYear('清')">        <span>清</span>         <span v-show="relicNumber.year['清'] != 0">{{ relicNumber.year['清'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-41" @click="setYear('中华民国')">  <span>中华民国</span>    <span v-show="relicNumber.year['中华民国'] != 0">{{ relicNumber.year['中华民国'] }}件/套</span></el-menu-item>
            <el-menu-item index="1-3-42" @click="setYear('中华人民共和国')"> <span>中华人民共和国</span> <span v-show="relicNumber.year['中华人民共和国'] != 0">{{ relicNumber.year['中华人民共和国'] }}件/套</span></el-menu-item>
          </el-submenu>
          <el-menu-item index="2-4" @click="setYear('年代不详')" class="ej_bg ej_one" >
            <span>年代不详</span> <span v-show="relicNumber.year['年代不详'] != 0">{{ relicNumber.year['年代不详'] }}件/套</span>
          </el-menu-item>
          <el-menu-item index="2-5" @click="setYear('其他')" class="ej_bg ej_one">
            <span>其他</span> <span v-show="relicNumber.year['其他'] != 0">{{ relicNumber.year['其他'] }}件/套</span>
          </el-menu-item>
        </el-submenu>

        <el-submenu index="3" v-if="relicNumber.level">
          <template #title>
            <span>级别</span>
          </template>
          <div class="ej_bg">
            <el-menu-item index="3-1" @click="setLevel('')"><span>全部藏品</span> <span>{{ relicNumber.total }}件/套</span></el-menu-item>
          <el-menu-item index="3-2" @click="setLevel('一级')"><span>一级</span> <span>{{ relicNumber.level['一级'] }}件/套</span></el-menu-item>
          <el-menu-item index="3-3" @click="setLevel('二级')"><span>二级</span> <span>{{ relicNumber.level['二级'] }}件/套</span></el-menu-item>
          <el-menu-item index="3-4" @click="setLevel('三级')"><span>三级</span> <span>{{ relicNumber.level['三级'] }}件/套</span></el-menu-item>
          <el-menu-item index="3-5" @click="setLevel('一般')"><span>一般</span> <span>{{ relicNumber.level['一般'] }}件/套</span></el-menu-item>
          <el-menu-item index="3-6" @click="setLevel('未定级')"><span>未定级</span> <span>{{ relicNumber.level['未定级'] }}件/套</span></el-menu-item>
          </div>
          
        </el-submenu>

        <el-submenu index="4" v-if="relicNumber.classes">
          <template #title>
            <span>类别</span>
          </template>
          <div class="ej_bg">
            <el-menu-item index="4-1" @click="setClasses('玉石器、宝石')"    ><span>玉石器、宝石</span>  <span v-show="relicNumber.classes['玉石器、宝石'] != 0">{{ relicNumber.classes['玉石器、宝石'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-2" @click="setClasses('陶器')"><span>陶器</span>   <span v-show="relicNumber.classes['陶器'] != 0">{{ relicNumber.classes['陶器'] }}件/套</span> </el-menu-item>
            <el-menu-item index="4-3" @click="setClasses('瓷器')"><span>瓷器</span>  <span v-show="relicNumber.classes['瓷器'] != 0">{{ relicNumber.classes['瓷器'] }}件/套</span> </el-menu-item>
            <el-menu-item index="4-4" @click="setClasses('铜器')"><span>铜器</span> <span v-show="relicNumber.classes['铜器'] != 0">{{ relicNumber.classes['铜器'] }}件/套</span> </el-menu-item>
            <el-menu-item index="4-5" @click="setClasses('金银器')"><span>金银器</span> <span v-show="relicNumber.classes['金银器'] != 0">{{ relicNumber.classes['金银器'] }}件/套</span> </el-menu-item>
            <el-menu-item index="4-6" @click="setClasses('铁器、其他金属器')"><span>铁器、其他金属器</span> <span v-show="relicNumber.classes['铁器、其他金属器'] != 0">{{ relicNumber.classes['铁器、其他金属器'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-7" @click="setClasses('雕塑、造像')"><span>雕塑、造像</span> <span v-show="relicNumber.classes['雕塑、造像'] != 0">{{ relicNumber.classes['雕塑、造像'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-8" @click="setClasses('石器、石刻、砖瓦')"><span>石器、石刻、砖瓦</span> <span v-show="relicNumber.classes['石器、石刻、砖瓦'] != 0">{{ relicNumber.classes['石器、石刻、砖瓦'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-9" @click="setClasses('书法、绘画')"><span>书法、绘画</span> <span v-show="relicNumber.classes['书法、绘画'] != 0">{{ relicNumber.classes['书法、绘画'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-10" @click="setClasses('文具')"><span>文具</span> <span v-show="relicNumber.classes['文具'] != 0">{{ relicNumber.classes['文具'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-11" @click="setClasses('甲骨')"><span>甲骨</span> <span v-show="relicNumber.classes['甲骨'] != 0">{{ relicNumber.classes['甲骨'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-12" @click="setClasses('玺印符牌')"><span>玺印符牌</span><span v-show="relicNumber.classes['玺印符牌'] != 0">{{ relicNumber.classes['玺印符牌'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-13" @click="setClasses('钱币')"><span>钱币</span> <span v-show="relicNumber.classes['钱币'] != 0">{{ relicNumber.classes['钱币'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-14" @click="setClasses('牙骨角器')"><span>牙骨角器</span> <span v-show="relicNumber.classes['牙骨角器'] != 0">{{ relicNumber.classes['牙骨角器'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-15" @click="setClasses('竹木雕')"><span>竹木雕</span> <span v-show="relicNumber.classes['竹木雕'] != 0">{{ relicNumber.classes['竹木雕'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-16" @click="setClasses('家具')"><span>家具</span><span v-show="relicNumber.classes['家具'] != 0">{{ relicNumber.classes['家具'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-17" @click="setClasses('珐琅器')"><span>珐琅器</span><span v-show="relicNumber.classes['珐琅器'] != 0">{{ relicNumber.classes['珐琅器'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-18" @click="setClasses('织绣')"><span>织绣</span><span v-show="relicNumber.classes['织绣'] != 0">{{ relicNumber.classes['织绣'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-19" @click="setClasses('古籍图书')"><span>古籍图书</span><span v-show="relicNumber.classes['古籍图书'] != 0">{{ relicNumber.classes['古籍图书'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-20" @click="setClasses('碑帖拓本')"><span>碑帖拓本</span><span v-show="relicNumber.classes['碑帖拓本'] != 0">{{ relicNumber.classes['碑帖拓本'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-21" @click="setClasses('武器')"><span>武器</span><span v-show="relicNumber.classes['武器'] != 0">{{ relicNumber.classes['武器'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-22" @click="setClasses('文件、宣传品')"><span>文件、宣传品</span><span v-show="relicNumber.classes['文件、宣传品'] != 0">{{ relicNumber.classes['文件、宣传品'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-23" @click="setClasses('档案文书')"><span>档案文书</span><span v-show="relicNumber.classes['档案文书'] != 0">{{ relicNumber.classes['档案文书'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-24" @click="setClasses('名人遗物')"><span>名人遗物</span><span v-show="relicNumber.classes['名人遗物'] != 0">{{ relicNumber.classes['名人遗物'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-25" @click="setClasses('玻璃器')"><span>玻璃器</span><span v-show="relicNumber.classes['玻璃器'] != 0">{{ relicNumber.classes['玻璃器'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-26" @click="setClasses('乐器、法器')"><span>乐器、法器</span><span v-show="relicNumber.classes['乐器、法器'] != 0">{{ relicNumber.classes['乐器、法器'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-27" @click="setClasses('皮革')"><span>皮革</span><span v-show="relicNumber.classes['皮革'] != 0">{{ relicNumber.classes['皮革'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-28" @click="setClasses('音像制品')"><span>音像制品</span><span v-show="relicNumber.classes['音像制品'] != 0">{{ relicNumber.classes['音像制品'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-29" @click="setClasses('票据')"><span>票据</span><span v-show="relicNumber.classes['票据'] != 0">{{ relicNumber.classes['票据'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-30" @click="setClasses('交通、运输工具')"><span>交通、运输工具</span><span v-show="relicNumber.classes['交通、运输工具'] != 0">{{ relicNumber.classes['交通、运输工具'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-31" @click="setClasses('度量衡器')"><span>度量衡器</span><span v-show="relicNumber.classes['度量衡器'] != 0">{{ relicNumber.classes['度量衡器'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-32" @click="setClasses('标本')"><span>标本</span><span v-show="relicNumber.classes['标本'] != 0">{{ relicNumber.classes['标本'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-33" @click="setClasses('化石')"><span>化石</span><span v-show="relicNumber.classes['化石'] != 0">{{ relicNumber.classes['化石'] }}件/套</span></el-menu-item>
            <el-menu-item index="4-34" @click="setClasses('其他')"><span>其他</span><span v-show="relicNumber.classes['其他'] != 0">{{ relicNumber.classes['其他'] }}件/套</span></el-menu-item>
          </div>
        </el-submenu>
      </el-menu> -->
    </el-col>
  </div>

  <div class="list_box">
    <!-- 检索 -->
    <div class="search_box">
      <!-- <el-select v-model="searchType" placeholder="请选择">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select> -->
      <el-input v-model="searchKeyword" :placeholder="searchType == '2' ? '请输入类别名称' : '请输入藏品名称'"></el-input>
      <a href="javascript:;" class="search_submit" @click="getList(1)">
        <!-- <img src="../assets/images/search_icon.png" alt="" /> -->
        <div :style="{'background':conf.theme_color}" class="search-button">检索</div>
      </a>
      <!-- <a href="javascript:;" class="search_submit" @click="showSearch = true">
        <img src="../assets/images/search-icon2.png" alt="" />
      </a> -->
    </div>

    <div class="content_tab">
      <p>
        检索结果: <span>{{ list.total }}</span> 件
      </p>
      <!-- <div class="tab_tab">
        <a href="javascript:;" :class="num == 0 ? 'active' : ''" @click="num = 0">
          <img src="../assets/images/img_list.svg" alt="" />
        </a>
        <a href="javascript:;" :class="num == 1 ? 'active' : ''" @click="num = 1,highSearch">
          <img src="../assets/images/list_list.svg" alt="" />
        </a>
      </div> -->
    </div>

    <div class="tab_content">
      <!-- 文物列表 图文 -->
      <div class="img_list" v-show="num == 0">
        <ul>
          <li v-for="(item, index) in list.data" :key="index">
            <view @click="openWindow(item)" class="ul_li img-item"
              :to="{ path: 'collectDetail', query: { collectid: item.relic_id } }">
              <div class="left_img"><img :src="item.thumb" /></div>
              <div class="right_text">
                <div class="item-info">
                  <h3>{{ item.relic_name }}</h3>
                  <p>{{ item.classes || " " }}</p>
                  <p>{{ item.year || " " }}</p>
                  <p :style="{ color: item.level ? '#7e7e7e' : '#fff' }">
                    {{ item.level || " 1" }}
                  </p>
                </div>
                <p class="museum-name">{{ item.museum }}</p>
              </div>
            </view>
          </li>
        </ul>
      </div>

      <!-- 文物列表 表格 -->
      <div class="text_list" v-show="num == 1">
        <el-table ref="multipleTable" stripe :data="list.data" tooltip-effect="dark" style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column type="index" label="序号" width="100"> </el-table-column>
          <el-table-column prop="img" label="缩略图" width="100">
            <template v-slot:default="scope">
              <el-image :src="scope.row.thumb" />
            </template>
          </el-table-column>
          <el-table-column prop="relic_name" label="名称">
            <template v-slot:default="scope" show-overflow-tooltip>
              <router-link class="ul_li" :to="{
                path: 'collectDetail',
                query: { collectid: scope.row.relic_id },
              }">
                {{ scope.row.relic_name }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="classes" label="类别" width="150"></el-table-column>
          <el-table-column prop="year" label="年代" width="300"></el-table-column>
          <el-table-column prop="level" label="级别" width="100"></el-table-column>
          <el-table-column prop="museum" label="保管单位" width="200" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page_bottom">
      <!-- <a href="javascript:;" class="export" @click="onBatchExport">导出</a> -->
      <!-- <a href="javascript:;" class="export" @click="downExcel">导出</a> -->
      <div class="block">
        <el-config-provider :locale="locale">
          <el-pagination @size-change="handleSizeChange" background :style="{'--el-color-primary':conf.theme_color}"  @current-change="handleCurrentChange" v-model="currentPage"
            :page-sizes="[12, 50, 100, 200]" :page-size="list.per_page" layout="sizes, prev, pager, next"
            :total="list.total">
          </el-pagination>
        </el-config-provider>
      </div>
    </div>


    <div class="detail-panel" v-if="showSearch">
      <div class="details">
        <div class="close-details" @click="showSearch = false">
          <img src="../assets/images/close.png" />
        </div>
        <div class="details_in">
          <div class="details-title">
            <div class="collection-detail-title">
              <img src="../assets/images/left.png" />
              <div class="title-label">高级搜索</div>
              <img src="../assets/images/right.png" />
            </div>
          </div>
          <div class="details-content">
            <div class="addUser">
              <el-form :label-position="labelPosition" :model="queryInfo.data" label-width="80px" class="user_form">
                <div class="label_museum_left">
                  <el-form-item label="保管单位">
                    <!-- <el-input v-model="queryInfo.collect_year" placeholder="请填写"></el-input> -->
                    <el-select v-model="queryInfo.data.museum_id" placeholder="请选择">
                      <el-option
                          v-for="item in museumList"
                          :key="item.museum_name"
                          :label="item.museum_name"
                          :value="item.museum_id"
                        >
                      </el-option> 
                    </el-select>
                  </el-form-item>
                  <el-form-item label="藏品编号">
                    <el-input v-model="queryInfo.data.number_txt" placeholder="请填写"></el-input>
                  </el-form-item>
                  <el-form-item label="年代">
                    <!-- <el-select v-model="queryInfo.museum_id" placeholder="请选择">
                      <el-option
                        v-for="item in museum"
                        :label="item.museum_name"
                        :key="item.museum_id"
                        :value="item.museum_id"
                      ></el-option>
                    </el-select> -->
                    <el-input v-model="queryInfo.data.year" placeholder="请填写"></el-input>
                  </el-form-item>
                  <el-form-item label="文物级别">
                    <el-select v-model="queryInfo.data.level" placeholder="请选择">
                      <el-option label="一级" value="一级"></el-option>
                      <el-option label="二级" value="二级"></el-option>
                      <el-option label="三级" value="三级"></el-option>
                      <el-option label="一般" value="一般"></el-option>
                      <el-option label="未定级" value="未定级"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="类别">
                    <!-- <el-input v-model="queryInfo.data.classes" placeholder="请填写"></el-input> -->
                    <el-select v-model="queryInfo.data.classes" placeholder="请选择">
                      <el-option label="玉石器 、宝石" value="玉石器 、宝石"></el-option>
                      <el-option label="陶器" value="陶器"></el-option>
                      <el-option label="瓷器" value="瓷器"></el-option>
                      <el-option label="铜器" value="铜器"></el-option>
                      <el-option label="金银器" value="金银器"></el-option>
                      <el-option label="铁器、其他金属器" value="铁器、其他金属器"></el-option>
                      <el-option label="漆器" value="漆器"></el-option>
                      <el-option label="雕塑、造像" value="雕塑、造像"></el-option>
                      <el-option label="石器、石刻、砖瓦" value="石器、石刻、砖瓦"></el-option>
                      <el-option label="书法、绘画" value="书法、绘画"></el-option>
                      <el-option label="文具" value="文具"></el-option>
                      <el-option label="甲骨" value="甲骨"></el-option>
                      <el-option label="玺印符牌" value="玺印符牌"></el-option>
                      <el-option label="钱币" value="钱币"></el-option>
                      <el-option label="牙骨角器" value="牙骨角器"></el-option>
                      <el-option label="竹木雕" value="竹木雕"></el-option>
                      <el-option label="家具" value="家具"></el-option>
                      <el-option label="珐琅器" value="珐琅器"></el-option>
                      <el-option label="织绣" value="织绣"></el-option>
                      <el-option label="古籍图书" value="古籍图书"></el-option>
                      <el-option label="碑帖拓本" value="碑帖拓本"></el-option>
                      <el-option label="武器" value="武器"></el-option>
                      <el-option label="邮品" value="邮品"></el-option>
                      <el-option label="文件、宣传品" value="文件、宣传品"></el-option>
                      <el-option label="档案文书" value="档案文书"></el-option>
                      <el-option label="名人遗物" value="名人遗物"></el-option>
                      <el-option label="玻璃器" value="玻璃器"></el-option>
                      <el-option label="乐器、法器" value="乐器、法器"></el-option>
                      <el-option label="皮革" value="皮革"></el-option>
                      <el-option label="音像制品" value="音像制品"></el-option>
                      <el-option label="票据" value="票据"></el-option>
                      <el-option label="交通、运输工具" value="交通、运输工具"></el-option>
                      <el-option label="度量衡器" value="度量衡器"></el-option>
                      <el-option label="标本" value="标本"></el-option>
                      <el-option label="化石" value="化石"></el-option>
                      <el-option label="其他" value="其他"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="文物来源">
                    <!-- <el-input v-model="queryInfo.from" placeholder="请填写"></el-input> -->
                    <el-select v-model="queryInfo.data.from" placeholder="请选择">
                      <el-option label="征集购买" value="征集购买"></el-option>
                      <el-option label="接受捐赠" value="接受捐赠"></el-option>
                      <el-option label="依法交换" value="依法交换"></el-option>
                      <el-option label="拨交" value="拨交"></el-option>
                      <el-option label="移交" value="移交"></el-option>
                      <el-option label="旧藏" value="旧藏"></el-option>
                      <el-option label="发掘" value="发掘"></el-option>
                      <el-option label="拣选" value="拣选"></el-option>
                      <el-option label="其他" value="其他"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="质地">
                    <el-input v-model="queryInfo.data.quality_three" placeholder="请填写"></el-input>
                  </el-form-item>
                  <el-form-item label="通长">
                    <el-input v-model="queryInfo.data.size_leng" placeholder="请填写"></el-input>
                  </el-form-item>
                  <el-form-item label="通宽">
                    <el-input v-model="queryInfo.data.size_width" placeholder="请填写"></el-input>
                  </el-form-item>
                  <el-form-item label="通高">
                    <el-input v-model="queryInfo.data.size_hegith" placeholder="请填写"></el-input>
                  </el-form-item>

                  <el-form-item label="完残程度">
                    <!-- <el-input v-model="queryInfo.data.Incomplete" placeholder="请填写"></el-input> -->
                    <el-select v-model="queryInfo.data.Incomplete" placeholder="请选择">
                      <el-option label="完整" value="完整"></el-option>
                      <el-option label="基本完整" value="基本完整"></el-option>
                      <el-option label="残缺" value="残缺"></el-option>
                      <el-option label="严重残缺（含缺失部件）" value="严重残缺（含缺失部件）"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="保存状态">
                    <!-- <el-input v-model="queryInfo.Incomplete_txt" placeholder="请填写"></el-input> -->
                    <el-select v-model="queryInfo.data.save_status" placeholder="请选择">
                      <el-option label="状态稳定，不需修复" value="状态稳定，不需修复"></el-option>
                      <el-option label="部分损腐，需要修复" value="部分损腐，需要修复"></el-option>
                      <el-option label="腐蚀损毁严重，急需修复" value="腐蚀损毁严重，急需修复"></el-option>
                      <el-option label="已修复" value="已修复"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="质量范围">
                    <!-- <el-input v-model="queryInfo.weight_range" placeholder="请填写"></el-input> -->
                    <el-select v-model="queryInfo.data.weight_range" placeholder="请选择">
                      <el-option label="<0.01kg" value="<0.01kg"></el-option>
                      <el-option label="0.01-1kg" value="0.01-1kg"></el-option>
                      <el-option label="1-50kg" value="1-50kg"></el-option>
                      <el-option label="50-100kg" value="50-100kg"></el-option>
                      <el-option label="100-1000kg" value="100-1000kg"></el-option>
                      <el-option label=">1000kg" value=">1000kg"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="入藏时间">
                    <!-- <el-input v-model="queryInfo.collect_year" placeholder="请填写"></el-input> -->
                    <el-select v-model="queryInfo.data.collect_time_range" placeholder="请选择">
                      <el-option label="1949.10.1前" value="1949.10.1前"></el-option>
                      <el-option label="1949.10.1-1965" value="1949.10.1-1965"></el-option>
                      <el-option label="1966-1976" value="1966-1976"></el-option>
                      <el-option label="1977-2000" value="1977-2000"></el-option>
                      <el-option label="2001至今" value="2001至今"></el-option>
                    </el-select>
                  </el-form-item>



                  
                </div>
              </el-form>
              <el-row>
                <el-button type="danger" size="medium" @click="getList(1, 1)">确定</el-button>
                <el-button size="medium" @click="reset()">重置</el-button>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style>
@import "~@/assets/css/sidebar.css";
@import "~@/assets/css/public.css";
@import "~@/assets/css/listContent.css";
</style>

<script>
import table2excel from "js-table2excel";

import { useStore } from "vuex";
import { computed, getCurrentInstance, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { ElConfigProvider, ElMessage } from "element-plus";

import zhCn from "element-plus/lib/locale/lang/zh-cn";

const param = {};
const searchKeyword = ref("");
const searchType = ref("");

export default {
  name: "ListContent",
  data() {
    return {
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "根据名称检索",
        },
        {
          value: "2",
          label: "根据类别检索",
        },
      ],
      saveUnit:[],
      searchType,
      searchKeyword,
      currentPage: 1,
      num: 0,
      locale: zhCn,

      // multipleSelection: [],
    };
  },

  mounted() {
    
    this.curMuseumId = window.sessionStorage.getItem("museumId");
    this.getDataList();
    this.getList(1);
    this.getCurPower();
    let token =  window.localStorage.getItem("token");
    let loginName =  window.localStorage.getItem("loginName");
    console.log(token)
    if(loginName&&token){
      let user = {}
      user.name = loginName;

      user.token = token;
      this.setUserInfo(user);
    }
  },
  components: {
    ElConfigProvider,
  },

  setup() {
    const rt = useRouter();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    let museum = ref([]);
    let relicNumber = ref(""); //文物数量
    let curLevel = ref(""); //等级
    let curMuseumId = ref(""); //博物馆id
    let curClasses = ref(""); //类别
    let curYear = ref(""); //年代
    let list = ref([]);
    const conf = computed(() => store.state.config )
    let showSearch = ref(false);
    let museumList = ref([]);
    
    let defaultActive = ref(0);
    let downloadUrl = ref("");
    let multipleSelection = ref([]);
    let queryInfo = reactive({
      data: {}
    });
    const treeRef = ref([]);
    //博物馆列表
    const getDataList = (val) => {
      var param = {};

      if (val) {
        param.checked = 1;
        param.museum_id = val;
      }

      proxy.$api.getTreeList(param).then((res) => {
        let data = res.data.sidebar;
        window.document.title = res.data.conf.title
        store.commit("setConfig", res.data.conf);
        console.log(store.state.config)
        if (val) {
          bindNavNum(museum.value[1].children, data[0].children);
          bindNavNum(museum.value[2].children, data[1].children);
          bindNavNum(museum.value[3].children, data[2].children);
        } else if (!val && museum.value.length) {
          return false;
        } else {
          museum.value = data;
          console.log(curMuseumId.value);
          defaultActive.value = data.findIndex((item) => {
            return item.museum_id == curMuseumId.value;
          });
        }

      });
    };

    const setUserInfo = (user)=>{

      console.log(user)
      store.commit("setUser", user);
    }

    const getCurPower = () => {
      var param = {};
      proxy.$api.getCurPower(param).then((res) => {
        let data = res.data;
        window.sessionStorage.setItem("userPower", JSON.stringify(data));
        console.log(data);
      });
    };

    const bindNavNum = (m, d) => {
      for (let i = 0; i < d.length; i++) {
        m[i].num = d[i].num;
        if (d[i].children) {
          bindNavNum(m[i].children, d[i].children);
        }
      }
    };


    const reset = () => {
      queryInfo.data = {}
    };


    const handleCheckChange = (
      data
      // checked,
      // indeterminate
    ) => {
      getCheckedKeys(data);
      // console.log(data, checked, indeterminate)
    };

    const getCheckedKeys = (data) => {
      // console.log(treeRef.value.getCheckedNodes(false))
      let arr = treeRef.value.getCheckedNodes(false);
      let str = "";
      let strYear = "";
      let strLevel = "";
      let strClasses = "";
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].type == "museum") {
          str += "," + arr[i].id;
        }
        if (arr[i].type == "year") {
          strYear += "," + arr[i].name;
        }
        if (arr[i].type == "level") {
          strLevel += "," + arr[i].name;
        }
        if (arr[i].type == "classes") {
          strClasses += "," + arr[i].name;
        }
      }

      if (data.type == "museum") {
        str = str.replace(",", "");
        curMuseumId.value = str;
        getDataList(str);
        getList();
      } else {
        if (data.type == "year") {
          curYear.value = strYear.replace(",", "");
        }

        if (data.type == "level") {
          curLevel.value = strLevel.replace(",", "");
        }

        if (data.type == "classes") {
          curClasses.value = strClasses.replace(",", "");
        }

        getList();
      }
    };
    //设置博物馆id
    const setMuseumId = (id) => {
      clearForm();
      curMuseumId.value = id;
      getList(1);
      // getRelicNum(id)
    };
    //设置年代
    const setYear = (year) => {
      clearForm();
      curYear.value = year;
      getList(1);
    };

    const clearForm = () => {
      curLevel.value = "";
      curClasses.value = "";
      curYear.value = "";
    };
    //文物数量
    const getRelicNum = (museumId) => {
      var param = {};

      if (museumId) {
        param.museum_id = museumId;
      }
      proxy.$api.getRelicTotal(param).then((res) => {
        relicNumber.value = res.data;
      });
    };

    const highSearch = ()=>{

      queryInfo.data.museum_id =''
    }

    //设置级别
    const setLevel = (level) => {
      clearForm();
      curLevel.value = level;
      getList(1);
    };
    //设置类别
    const setClasses = (classes) => {
      clearForm();
      curClasses.value = classes;
      getList(1);
    };

    //文物列表
    const getList = (page, isHigh) => {
      let _param = {}

      if (isHigh) {
        _param = queryInfo.data
        _param.page = page;
        _param.page_size = param.page_size;
        // _param.museum_id = curMuseumId.value;
      } else {
        _param.page = page;
        _param.page_size = param.page_size;
        _param.relic_name = "";
        _param.classes = curClasses.value;
        _param.level = curLevel.value;
        _param.museum_id = curMuseumId.value;
        _param.year = curYear.value;

        if (searchType.value == 2) {
          _param.classes = searchKeyword.value;
        } else {
          _param.relic_name = searchKeyword.value;
        }
      }


      proxy.$api.getList(_param).then((res) => {
        if (res.code == "200") {
          list.value = res.data;

          if (isHigh) {
            showSearch.value = false;
            // reset();
          }
        } else {
          ElMessage({
            type: "success",
            message: res.msg,
          });
        }
      });
    };

    const ToExcel = (fileName, str) => {
      var raw = window.atob(str);
      var uInt8Array = new Uint8Array(raw.length);
      for (var i = 0; i < raw.length; i++) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      const link = document.createElement("a");
      const blob = new Blob([uInt8Array], {
        type: "application/vnd.ms-excel",
      });

      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    }

    const downExcel = () => {

      param.museum_id = curMuseumId.value;
      let arr = multipleSelection.value;
      let newArr = []
      for (let i = 0; i < arr.length; i++) {
        newArr.push(arr[i].relic_id)
      }
      param.relic_id = newArr;
      console.log(1111111111111);
      proxy.$api.downExcel(param).then((res) => {

        let a = document.createElement('a');
        a.download = res.name;
        a.href = res.file;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);



      });
    };


    //博物馆列表
    const getMuseumList = () => {
      var param = {
      };
      proxy.$api.getMuseumList(param).then((res) => {
        museumList.value = res.data.data;
      });
    };


    const openWindow = (item) => {
      // :to="{ path: 'collectDetail', query: { collectid: item.relic_id } }"
      const { href } = rt.resolve({
        path: "collectDetail",
        query: { collectid: item.relic_id },
      });
      window.open(href, "_blank");
    };

    const handleNodeClick = (data) => {
      if (data.type == "museum" && data.name != "保管单位") {
        setMuseumId(data.id);
      }

      if (data.type == "year" && data.name != "年代") {
        setYear(data.name);
      }

      if (data.type == "level" && data.name != "级别") {
        setLevel(data.name);
      }

      if (data.type == "classes" && data.name != "类别") {
        setClasses(data.name);
      }
    };


    const handleSelectionChange = (val) => {
      multipleSelection.value = val;
      console.log(multipleSelection.value)
    };


    const state = computed(() => useStore().state.client);
    return {
      museum,
      getList,
      getDataList,
      getRelicNum,
      relicNumber,
      curMuseumId,
      list,
      state,
      getMuseumList,
      museumList,
      setLevel,
      setMuseumId,
      setClasses,
      setYear,
      highSearch,
      store,
      rt,
      reset,
      setUserInfo,
      clearForm,
      defaultActive,
      handleNodeClick,
      handleCheckChange,
      getCheckedKeys,
      treeRef,
      openWindow,
      queryInfo,
      getCurPower,
      downloadUrl,
      downExcel,
      ToExcel,
      conf,
      showSearch,
      multipleSelection,
      handleSelectionChange
    };
  },

  methods: {
    
    //每页显示记录数
    handleSizeChange(val) {
      param.page_size = val;
      var data = this.queryInfo.data;
      var arr = Object.keys(data);
      if (arr.length) {
        this.getList(1, 1);
      } else {
        this.getList(1);
      }

    },

    //分页数据
    handleCurrentChange(val) {
      var data = this.queryInfo.data;
      var arr = Object.keys(data);
      if (arr.length) {
        this.getList(val, 1);
      } else {
        this.getList(val);
      }

    },

    //
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    //导出execl
    onBatchExport() {
      const column = [
        {
          title: "ID",
          key: "relic_id",
          type: "text",
        },
        {
          title: "缩略图",
          key: "thumb",
          type: "image",
          width: 150,
          height: 150,
        },
        {
          title: "名称",
          key: "relic_name",
          type: "text",
        },
        {
          title: "类别",
          key: "classes",
          type: "text",
        },
        {
          title: "年代",
          key: "year",
          type: "text",
        },
        {
          title: "级别",
          key: "level",
          type: "text",
        },
        {
          title: "保管单位",
          key: "museum",
          type: "text",
        },
      ];

      // const datas = [
      //   {
      //     id: 1,
      //     sciencename: "ss",
      //     image:
      //       "http://kydww.museumcloud.com.cn/file/relic/2/1783/thumb/T-A-0001.jpg",
      //   },
      // ];

      const excelData = JSON.parse(JSON.stringify(this.list));
      const datas = excelData.data;
      console.log(excelData);
      // const datas = execl_list.value;
      table2excel(column, datas, "文物资料表");
    },
  },
};
</script>
<style>
.el-tree {
  /* background: #735b41 !important;
  color: #ccc !important; */
  background: transparent!important;
}



.el-tree-node:focus>.el-tree-node__content {
  /* background-color: #69523A!important; */
  background: rgba(255, 255, 255, 0.05) !important;
}

.el-tree-node__content:hover {
  /* background-color: #69523a !important;
  color: #ccc; */
  background: transparent!important;
}

.el-tree-node .is-current {
  background: rgba(255, 255, 255, 0.05) !important;
  color: #fff !important;
}

.el-tree-node__content {
  height: 40px !important;
  line-height: 40px;
}

.el-tree-node__content:hover.is-active {
  background: rgba(255, 255, 255, 0.05);
  color: #fff !important;
}

.el-tree>.el-tree-node {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
}

.el-tree-node {
  color: #ccc;
}

.el-tree-node__content {
  padding: 10px 0;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}


.right_text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 88%;
}

.right_text p {
  font-size: 13px !important;
}

.museum-name {
  color: #333 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #dea647 !important;
  border-color: #d5d13b !important;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #dea647 !important;
  border-color: #d5d13b !important;
}

.img-item {
  cursor: pointer;
}
</style>
