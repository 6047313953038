<template>
  <div class="footer"  :style="{'background':config.color_bottom}" >
      <!-- <h2>洛阳考古研究院</h2> -->
      <h2>{{config.title}}</h2>
  </div>
</template>

<script>
import { useStore } from "vuex"
import { computed } from "vue";
// import { useRouter } from 'vue-router'
export default {
  setup(){
    // const router = useRouter()
    const store = useStore()
    const config = computed(() => store.state.config )
    // const target = type =>{
    //   if(type==='home'){
    //     router.push('/home')
    //   }else{
    //     console.log(1);
    //     router.push('/product')
    //   }
    // }
    return{
      // target,
      store,
      config
    }
  }
};
</script>

<style>
@import "~@/assets/css/public.css";
</style>