<template>
  <div id="app">
    <Header v-if="path != '/login'"  />
    <router-view />
    <div v-if="state=='pc'">
      <Footer
      v-if="path != '/login'" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { onBeforeMount, ref ,reactive} from '@vue/runtime-core';
 
export default {
  name : 'App',
  data(){
    return {
      path:''
    }
  },
  mounted(){
    if(!this.userStore){
      let userStore = {}
      userStore.token =  window.sessionStorage.getItem('token')
      userStore.name = window.sessionStorage.getItem('loginName')
      userStore.id =  window.sessionStorage.getItem('userId')
      this.store.commit('setUser',userStore)
    }
  },
  watch:{
    $route(){
      console.log( this.$router.currentRoute.value.path);
      this.path = this.$router.currentRoute.value.path
    }
  },
  components:{
      Header,
      Footer
  },
  setup(){
    const store = useStore()
    const userStore = reactive(store.state.userInfo)
    const state = ref('pc')
    const setMode=()=>{
    
      let w=document.documentElement.clientWidth;
      if(w>960){
        state.value = 'pc'
        store.state.client==='mobile'?store.commit('setWidth','pc'):null
        return
      }
      state.value = 'mobile'
      store.state.client==='pc'?store.commit('setWidth','mobile'):null
    }
  
    onBeforeMount(setMode)
    window.addEventListener('resize',setMode)
    return {
      state,
      store,
      userStore
    }
  }  
}
</script>

<style lang="scss">

</style>
