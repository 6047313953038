<template>
  <div class="head" :style="{'background':config.color_top}" >
    <!-- <div class="nav">

      <router-link to="/charts"  >数据看板</router-link>

      <router-link to="/collect">藏品检索</router-link>

      <a href="javascript:;" @click="toEdit" >添加藏品</a>

    </div> -->
    <div class="title_logo"    >
      <img :src="config.logo" alt="" />
    </div>
    <div class="user_info" 
    @mouseover="mouseover" @mouseleave="mouseleave" >

      <!-- <i class="el-icon-location-information"></i>
      <p>{{museumName||'超级管理员'}}</p> -->
      <!-- <router-link to="/userPage" class="user">

        <el-icon :size="20">
          <User />
        </el-icon>
        <p>{{museumName||'超级管理员'}}  {{userName}}</p>

      </router-link> -->
      
      <div class="user-name" v-if="userInfo&&userInfo.name">
        <el-icon :size="20">
          <User />
        </el-icon>
        <p>{{userInfo.name}}</p>

      </div>
      
      <!-- <div class="login_panel" v-if="show"  >
          <router-link to="/userPage" >
              <p>用户中心</p>
          </router-link>
          <div class="split_login"></div>
          <router-link to="/login" >
            <p>退出</p>
          </router-link>
      </div> -->
    </div>
  </div>
</template>

<script> 
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useRoute,useRouter } from "vue-router";
import {  ElMessage } from "element-plus";
export default {
  setup() { 
    const state = computed(() => useStore().state.client);
    const activeIndex = ref("home")
    const route = useRoute()
    const router = useRouter();
    const store = useStore();
    const show = ref(false)
    const userName = ref(window.localStorage.getItem('loginName'))
    const museumName = ref(window.localStorage.getItem('museumName'))
    const config = computed(() => store.state.config )

    const userInfo = computed(() => store.state.userInfo )
    const mouseover = ()=>{
      show.value = true
    }
    const mouseleave = ()=>{
      show.value = false
    }

    const toEdit =()=>{
      let userPower = window.localStorage.getItem('userPower')
      userPower = JSON.parse(userPower)
      console.log(userPower)
      if(userPower.relic.add=="1"){
         router.push({
            path: "/edit",
            query: { },
          });
      }else{
          ElMessage({
            type: "message",
            message: "没有添加权限!",
          });
      }
      
    }

    const reload =()=>{
      router.push({
            path: "/charts",
            query: { },
          });
          setTimeout(()=>{
            window.location.reload();
          },10)
          
    }

    
    watch(
      () => route.path,
      () => {
       
        
        activeIndex.value !== route.path.substring(1)
          ? (activeIndex.value = route.path.substring(1))
          : null;
      }
    );
    return {
      state,
      activeIndex,
      mouseleave,
      show,
      mouseover,
      userName,
      userInfo,
      museumName,
      toEdit,
      reload,
      config,
      store
    };
  },
};
</script>

<style scoped>
@import "~@/assets/css/public.css";
.user{
  margin-left: 20px;
}

.user_info{
  display:flex;
  align-items: center;
}

.user-name{
  display:flex;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
}
</style>