import { createStore } from 'vuex'
 

export default createStore({
  state: {
    client: 'pc',
    userInfo: null,
    config:{}
  },
  getters:{
    getUser(state){
      return state.userInfo
    },
    getConfig(state){
      return state.config
    },
  },
  mutations: {
    setWidth(state,val){
      state.client=val
      console.log(val,'val')
    },
    setUser(state,user){
      state.userInfo = user
    },
    setConfig(state,obj){
      state.config = obj
    }
  },
  actions: {
    set_width({commit},val){
      commit('setWidth',val)
    },
    set_User({commit},user){
      commit('setUser',user)
    },
    set_Config({commit},obj){
      commit('setConfig',obj)
    }
  }
})
