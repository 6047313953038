import { createApp } from 'vue' 
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import './assets/css/reset.min.css'
import Api from './api/api' 
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import VueViewer  from 'v-viewer'
import 'viewerjs/dist/viewer.css'
 

const app = createApp(App).use(store).use(router).use(ElementPlus).use(VueViewer)



for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.config.globalProperties.$api = Api



app.mount('#app')



